<template>
  <v-app-bar
    :elevation="1"
    density="compact"
  >
    <template
      v-if="props.displayBackButton"
      #prepend
    >
      <v-btn
        density="compact"
        :icon="mdiChevronLeft"
        @click="previousStep"
      />
    </template>

    <v-app-bar-title>
      {{ title }}
       <v-icon
        v-if="hasError"
        size="small"
        :icon="mdiAlertCircleOutline"
        color="red"
      />
    </v-app-bar-title>

    <v-spacer></v-spacer>

    <v-btn
      color="success"
      class="mr-4"
      min-height="38px"
      min-width="100px"
      variant="flat"
      :disabled="!canProceed"
      data-qa="next-button"
      @click="nextStep"
    >
      {{ $translate('next') }}
    </v-btn>
  </v-app-bar>
</template>
<script setup lang="ts">
import type { PropType } from 'vue';
import { mdiChevronLeft, mdiAlertCircleOutline } from '@mdi/js';


const props = defineProps({
  title: {
    type: String,
    required: false,
    default: '',
  },
  beforeNext: {
    type: Function as PropType<() => Promise<boolean>>,
    required: false,
    default: null,
  },
  hasError: {
    type: Boolean,
    required: false,
    default: false,
  },
  displayBackButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  canProceed: {
    type: Boolean,
    required: false,
    default: true,
  },
});
const emit = defineEmits(['next', 'back']);
const nextStep = async () => {
  emit('next');
};
const previousStep = async () => {
  emit('back');
};

</script>